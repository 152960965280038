








import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

// components
import FolderForm from '@/components/forms/depository/FolderForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import { LargeKnowledgeResource } from '@/store/types'
import ManagerDepositoryModule from '@/store/modules/manager/depository'

@Component({
  components: {
    FolderForm,
  },
})
export default class DepositoryFilesFolder extends Mixins(NotifyMixin, PermissionsMixin) {
  private folder: LargeKnowledgeResource | null = null

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get folderID () {
    return +this.$route.params.folderID
  }

  private get monthID () {
    return +this.$route.params.monthID
  }

  private mounted () {
    ManagerDepositoryModule.fetchFolder({ courseID: this.courseID, courseFolderID: this.monthID, mediaFolderID: +this.folderID })
      .then(response => {
        this.folder = response
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.folder ? this.folder.folder : 'Папка',
    }
  }
}
